@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  margin: 0;
  padding: 0;
}

:root {
  // --primaryClr: #ffbd00;
  --primaryClr: #0cd664;
  --secondaryClr: #222a34;
  // --headClr: #101520;
  --headClr: #1a212a;
  // --bodyClr: #1a1e29;
  --bodyClr: #232a33;
  --whiteClr: #ffffff;
  --blackClr: #000000;
  --lightClr: rgba(255, 255, 255, 0.6);
  --oddsBack: #72bbef;
  --oddsLay: #f994ba;
  --siteRedClr: #af2f2f;
}
.light:root {
  --primaryClr: #ffbd00;
  --secondaryClr: #999;
  --headClr: rgba(0, 0, 0, 0.175);
  --bodyClr: #ddd;
  --whiteClr: #000000;
  --blackClr: #ffffff;
}

html,
body {
  background: var(--bodyClr);
  color: var(--whiteClr);
  font-size: 16px;
  // font-family: "Roboto", sans-serif;
  font-family: Averta Std, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  transition: all 0.3s;
}
.btn-primary {
  background: var(--primaryClr);
  color: var(--whiteClr);
  border-color: var(--primaryClr);
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 50px;
  padding: 0.425em 1.1875em;
  &:hover,
  &:focus {
    background: var(--primaryClr) !important;
    color: var(--whiteClr) !important;
    border-color: var(--primaryClr) !important;
  }
}
.btn-register {
  background: transparent;
  color: rgb(134, 151, 162);
  border-color: transparent;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 50px;
  padding: 0.425em 0.65em;
  &:hover,
  &:focus {
    background: var(--headClr) !important;
    color: var(--whiteClr) !important;
    opacity: 0.9;
    border-color: var(--headClr) !important;
  }
}
.btn-outline-primary {
  border-color: var(--primaryClr);
  &:hover,
  &:focus {
    background: transparent !important;
    color: #000000 !important;
    opacity: 0.9;
    border-color: var(--primaryClr) !important;
  }
}
.btn-outline-secondary {
  font-size: 11px;
  border-radius: 50px;
  padding: 6px 8px;
}
.back-link,
.back_btn {
  color: var(--primaryClr);
  font-size: 12px !important;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.65rem;
  border: 1px solid var(--primaryClr);
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.3s;
  &:hover,
  &:focus {
    background: var(--primaryClr);
    color: var(--blackClr);
  }
}
img {
  width: 100%;
  height: auto;
}
input,
input.form-control {
  border-radius: 0;
  border-color: var(--headClr);
  font-size: 14px;
  font-weight: 300;
  &:focus {
    border-color: var(--headClr);
    box-shadow: none !important;
  }
  &[type="number"] {
    appearance: textfield;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--bodyClr) inset !important;
  -webkit-text-fill-color: var(--whiteClr) !important;
  border-radius: 0 !important;
}
.header_open {
  .navbar {
    transform: translateX(0px) !important;
    .navbar-toggler {
      right: -12px !important;
    }
  }
}

.header {
  padding: 15px 0;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
  background: var(--bodyClr);
  width: 100%;
  transition: all 0.3s;
  .logo {
    max-width: 125px;
  }
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-230px);
    width: 230px;
    height: 100%;
    background: var(--bodyClr);
    padding: 1rem;
    z-index: 9;
    transition: all 0.3s;
    .navbar-toggler {
      position: absolute;
      top: 70px;
      right: -30px;
      background: var(--whiteClr);
      padding: 0.15rem 0.25rem;
      border-radius: 25px;
      z-index: 99;
      .navbar-toggler-icon {
        width: 20px;
        height: 22px;
      }
      &:focus {
        outline: 0;
        box-shadow: none;
      }
    }
    .navbar-collapse {
      position: fixed;
      top: 1rem;
      left: 1rem;
      width: 200px;
      height: 100%;
      display: block !important;
      .navbar-nav {
        width: 100%;
        flex-direction: column;
        a {
          color: var(--whiteClr);
          font-size: 1em;
          text-transform: capitalize;
          width: 100%;
          height: 30px;
          line-height: 1.33;
          margin-bottom: 1.1rem;
          padding: 0;
          &:last-child {
            margin-bottom: 0;
          }
          svg,
          img {
            fill: var(--whiteClr);
            width: 20px;
            height: 20px;
            margin-right: 7px;
          }
          &:hover {
            opacity: 0.8;
          }
          &.logoImg {
            width: fit-content;
            margin-bottom: 2.25rem;
            img {
              width: 150px;
              height: auto;
              @media screen and (max-width: 767.98px) {
                width: 120px;
              }
            }
          }
        }
      }
    }
  }
  .headerRight {
    display: flex;
    align-items: center;
    fill: var(--whiteClr);
    .path-stroke {
      stroke: var(--whiteClr);
    }
    .funds ul {
      background: var(--secondaryClr);
      border-radius: 25px;
      padding: 4px 12px;
      margin-right: 12px;
      li {
        position: relative;
        a {
          color: var(--whiteClr);
          span {
            padding-left: 8px;
          }
        }
      }
    }

    ul {
      display: flex;
      align-items: center;
      margin-right: 5px;
      li {
        margin: 0 20px;
        position: relative;
        .notification-count {
          width: 12px;
          height: 12px;
          background: var(--siteRedClr);
          color: var(--whiteClr);
          border-radius: 50%;
          position: absolute;
          right: -2px;
          top: -2px;
          font-size: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .menu-btn {
      padding: 0;
      background: none;
      border: none;
      &:hover,
      &:focus {
        background: none !important;
        border-color: transparent !important;
      }
      svg {
        width: 20px;
        height: auto;
        margin-left: 5px;
        fill: var(--primaryClr);
      }
    }
  }
}

.back-link {
  font-size: 10px;
  color: var(--primaryClr);
  text-transform: uppercase;
  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
  }
}
.offcanvas {
  border: none !important;
  z-index: 9999;
}
.offcanvas-header {
  background: var(--headClr);
  color: var(--whiteClr);
  .profile-img {
    width: 48px;
    height: 48px;
    background: var(--bodyClr);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    fill: var(--whiteClr);
  }
  .user-info {
    color: var(--whiteClr);
    font-size: 14px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      font-size: 16px;
      font-weight: bold;
    }
    p {
      margin: 0;
    }
  }
  .btn-close {
    opacity: 1;
  }
}
.offcanvas-body {
  background: var(--bodyClr);
  .balance {
    font-size: 16px;
    color: var(--whiteClr);
    display: flex;
    align-items: center;
    svg {
      margin-right: 14px;
      fill: var(--primaryClr);
      stroke: var(--primaryClr);
    }
  }
  .balance-amount {
    text-align: right;
    font-weight: bold;
    font-size: 16px;
    color: var(--whiteClr);
  }
  .btn {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
  }
  .menu-items {
    border-top: 1px solid var(--headClr);
    margin-top: 15px;
    li {
      border-bottom: 1px solid var(--headClr);
      a {
        display: flex;
        align-items: center;
        padding: 17px 1rem;
        font-size: 16px;
        color: var(--whiteClr);
        svg {
          margin-right: 15px;
          width: 22px;
          height: auto;
        }
      }
    }
  }
  ul.social_links {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    li {
      margin: 0 10px;
      position: relative;
      svg {
        fill: var(--whiteClr);
        stroke: var(--whiteClr);
        cursor: pointer;
      }
      .notification-count {
        width: 12px;
        height: 12px;
        background: var(--siteRedClr);
        color: var(--whiteClr);
        border-radius: 50%;
        position: absolute;
        right: -2px;
        top: -2px;
        font-size: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.form-steps {
  text-align: center;
}
.mainBanner {
  .banner_info {
    background: radial-gradient(
      43.89% 132.14% at 50% 43.3%,
      rgb(19, 121, 70) 0%,
      rgb(26, 33, 42) 100%
    );
    width: 100%;
    min-height: 7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding: 2rem;
    border-radius: 12px;
    .payment {
      display: flex;
      li {
        max-height: 36px;
        display: flex;
        align-items: center;
        margin: 0px;
        padding: 0px 1rem;
        img {
          width: 100%;
          max-height: inherit;
          vertical-align: middle;
        }
      }
    }
  }
  .home_banner {
    img {
      border-radius: 0.5rem;
    }
    .slick-dots {
      display: none !important;
    }
    @media screen and (min-width: 768px) {
      display: none;
    }
  }
  .slick-dots {
    @media screen and (max-width: 767.98px) {
      bottom: -15px;
    }
    bottom: 0px;
    li {
      button {
        &::before {
          color: var(--primaryClr);
          font-size: 13px;
        }
      }
    }
  }
}
.main {
  transition: all 0.3s;
  .TopCategories {
    margin: 0 0px 30px;
    position: relative;
    .casino_headeing {
      position: absolute;
      top: 0;
      left: 0;
      width: 140px;
      height: 70px;
      line-height: 70px;
      background: var(--secondaryClr);
      color: var(--whiteClr);
      text-align: center;
      border-right: 2px solid var(--primaryClr);
    }
    .nav-tabs {
      display: flex;
      flex-wrap: nowrap;
      margin: 0 0 0 140px;
      padding: 0;
      background: var(--secondaryClr);
      list-style: none;
      overflow-x: auto;
      border: 0px;
      margin-bottom: 20px;
      .nav-item {
        button,
        .anav-link {
          background-size: 30px 30px !important;
          background-position: 27px 10px !important;
          background-repeat: no-repeat !important;
          color: var(--whiteClr);
          font-size: 14px;
          width: 92px;
          height: 100%;
          padding: 46px 5px 5px;
          border: 0;
          border-radius: 0;
          &.active {
            background: var(--bodyClr);
          }
          &#uncontrolled-tab-example-tab-Roulette {
            background-image: url("https://gbet567.com/images/Roulette_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Baccarat {
            background-image: url("https://gbet567.com/images/Baccarat_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Andar\ Bahar {
            background-image: url("https://gbet567.com/images/Andar_bahar_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Poker {
            background-image: url("https://gbet567.com/images/Poker_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Blackjack {
            background-image: url("https://gbet567.com/images/Blackjack_icons.png");
          }
          &#uncontrolled-tab-example-tab-TeenPatti {
            background-image: url("https://gbet567.com/images/Teenpatti.png");
          }
          &#uncontrolled-tab-example-tab-32Card {
            background-image: url("https://gbet567.com/images/casino.png");
          }
          &#uncontrolled-tab-example-tab-Seven {
            background-image: url("https://gbet567.com/images/seven.png");
          }
          &#uncontrolled-tab-example-tab-Dices {
            background-image: url("https://gbet567.com/images/dices.png");
          }
          &#uncontrolled-tab-example-tab-Sports {
            background-image: url("https://gbet567.com/images/sports.png");
          }
        }
      }
    }
    .tab-content {
      max-height: 300px;
      overflow: hidden;
      &.view_more {
        max-height: fit-content !important;
      }
    }
  }
  .games_tab {
    padding: 20px 0;
    ul {
      display: flex;
      overflow: auto hidden;
      justify-content: center;
      &::-webkit-scrollbar {
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 10px;
      }
      &:hover {
        &::-webkit-scrollbar-thumb {
          background: rgb(76, 81, 89);
        }
      }
      li {
        flex: 0 0 auto;
        margin-left: 0.65rem;
        &:first-child {
          margin-left: 0;
        }
        a {
          display: inline-block;
          color: rgb(134, 151, 162);
          position: relative;
          &.active,
          &:hover {
            color: var(--whiteClr);
          }
          &:after {
            position: absolute;
            content: "";
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 10px;
            height: 2px;
            background: var(--primaryClr);
            opacity: 0;
            transition: all 0.3s;
          }
          &.active {
            &:after {
              opacity: 1;
            }
          }
          .imgBox {
            width: 56px;
            height: 56px;
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            img {
              background: var(--headClr);
              width: 100%;
              height: auto;
              line-height: 0;
              padding: 1rem;
              border-radius: 25%;
              text-align: center;
              display: inline-block;
              font-size: 1.5rem;
              box-sizing: content-box;
            }
          }
          .text {
            font-size: 11px;
            max-width: 55px;
            white-space: normal;
            text-align: center;
            line-height: 1rem;
            margin-top: 0.5rem;
            letter-spacing: -0.1px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .sectionTitle {
    font-size: 18px;
    font-weight: normal;
    color: var(--whiteClr);
    position: relative;
    display: inline-block;
    margin: 20px 0 10px;
    padding-bottom: 5px;
    &:after {
      content: " ";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 50%;
      height: 1px;
      background: var(--primaryClr);
    }
  }
  .innerBanner {
    margin-bottom: 30px;
    border-radius: 10px;
    overflow: hidden;
    .link {
      width: 100%;
      background: var(--primaryClr);
      font-size: 11px;
      font-weight: 500;
      display: block;
      text-align: center;
      color: var(--whiteClr);
      text-transform: uppercase;
      padding: 8px;
    }
  }
  .payment-info {
    background: var(--headClr);
    margin: 0 -1rem;
    padding: 22px 1rem;
    color: var(--whiteClr);
    .balance {
      font-size: 16px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 14px;
        fill: var(--whiteClr);
        stroke: var(--whiteClr);
        opacity: 0.6;
      }
    }
    .balance-amount {
      text-align: right;
      font-weight: bold;
      font-size: 16px;
    }
    .btn {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      &.btn-outline-secondary {
        border-color: var(--whiteClr);
        color: var(--whiteClr);
      }
    }
  }
  .account-statement {
    h3 {
      border-bottom: 1px solid var(--whiteClr);
      font-size: 16px;
      font-weight: 300;
      margin: 12px -1rem 22px -1rem;
      padding: 0 1rem 12px 1rem;
    }
    .btn-primary {
      border-radius: 0;
      padding: 6px 10px;
    }
    ul {
      margin: 0 -1rem;
      border-top: 1px solid var(--whiteClr);
      li {
        padding: 22px 1rem;
        border-bottom: 1px solid var(--whiteClr);
        .h4 {
          font-size: 14px;
          margin-bottom: 0;
        }
        .h5 {
          margin-bottom: 0;
          font-size: 12px;
          letter-spacing: 0.86px;
        }
        .details {
          font-size: 12px;
          font-weight: 300;
        }
        .statue {
          font-size: 10px;
          font-weight: 300;
          letter-spacing: 0.7px;
        }
        small {
          margin-top: 10px;
          font-size: 10px;
          font-weight: 300;
          display: block;
        }
      }
    }
  }
  .profile-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    img {
      max-width: 60px;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 2px solid var(--primaryClr);
    }
    span {
      margin-top: 8px;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .edit-btn {
    background: var(--primaryClr);
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    fill: var(--headClr);
    padding: 4px;
    top: 58px;
    right: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .profile-info {
    th {
      background: var(--headClr);
      color: var(--whiteClr);
      border-color: #1a212a;
    }
    td {
      background: var(--bodyClr);
      color: var(--whiteClr);
      border-color: #1a212a;
    }
    .edit-btn {
      top: 0;
      right: 0;
      margin-left: auto;
    }
  }
}
.listings {
  .filter {
    margin-bottom: 20px;

    .dropdown-toggle {
      background: var(--primaryClr) !important;
      border: none !important;
      border-radius: 0;
      padding: 5px 15px;
    }
    .btn-outline-primary {
      width: 100%;
      padding: 5px;
      background: transparent;
      border-radius: 0 !important;
    }
    .dropdown-menu {
      border-radius: 0;
      border: 1px solid var(--whiteClr);
      width: 100% !important;
      white-space: pre-wrap;
      min-width: auto;
      padding: 0 !important;
      margin-top: -3px;
      a {
        border-bottom: 1px solid var(--whiteClr);
        color: var(--headClr);
        font-size: 14px;
        opacity: 0.8;
        padding: 13px 18px;
        &:last-child {
          border: none;
        }
      }
    }
  }
  .like-btn {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.4);
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px 9px;
    border-radius: 0 0 7px 0;
    display: none;
    svg {
      fill: var(--whiteClr);
    }
  }
}
.banner_sec {
  padding: 1.5rem 0;
  .banner_imgboxes {
    display: flex;
    align-items: center;
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 0;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: #20394c;
      border-radius: 25px;
      &:hover {
        background: #304b5f;
      }
    }
    &::-webkit-scrollbar-track {
      background: #0f212e;
      border-radius: 25px;
    }
    .img_box {
      flex: 0 0 33.33%;
      height: auto;
      padding: 0 8px 1rem;
      img {
        border-radius: 0.5rem;
      }
    }
  }
  @media screen and (max-width: 767.98px) {
    display: none;
  }
}
.steps-canvas {
  .offcanvas-header {
    background: var(--headClr);
    .offcanvas-title {
      font-size: 20px;
      font-weight: bold;
      color: var(--whiteClr);
    }
  }
  .form-steps {
    padding: 55px 30px 0;
    .h2 {
      font-size: 28px;
      font-weight: 500;
      color: var(--whiteClr);
    }
    p {
      font-size: 18px;
      font-weight: 300;
      color: var(--whiteClr);
    }
    input {
      background: var(--headClr);
      border-radius: 10px;
      border: 1px solid !important;
      border-color: var(--headClr) !important;
      padding: 14px 20px;
      color: var(--whiteClr);
    }
    .icon {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translate(0, -50%);
      fill: var(--whiteClr);
    }
    .flag-dropdown {
      background: transparent;
      border: 0;
      border-right: 1px solid var(--headClr);
      &.open,
      &:focus {
        background: transparent;
      }
      .selected-flag {
        background: transparent !important;
      }
      .country-list {
        background: var(--bodyClr) !important;
        overflow-y: auto;
        width: 308px;
        margin: 10px 0;
        text-align: left;
        @media only screen and (max-width: 767.98px) {
          width: 268px;
        }
        .country {
          display: none;
          &:first-child {
            display: block !important;
          }
          &.highlight,
          &:hover {
            background: var(--headClr) !important;
          }
        }
      }
    }
    .btn {
      padding: 9px 30px;
    }
    .form-link {
      font-size: 14px;
      color: var(--lightClr);
      text-decoration: underline;
      &:hover {
        opacity: 0.7;
      }
    }
    .sign-up {
      font-size: 14px;
      font-weight: 500;
      a {
        font-size: 16px;
        color: var(--primaryClr);
        text-decoration: underline;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}
.tabs-area {
  margin-top: 10px;
  ul {
    flex-wrap: nowrap;
    li {
      width: 100%;
      &:not(:last-child) {
        border-right: 1px solid var(--whiteClr);
      }
      .nav-link {
        width: 100%;
        background: var(--headClr);
        color: rgba(255, 255, 255, 0.6);
        border-radius: 0;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 17px 10px;
        border: none;
        &.active {
          background: var(--headClr);
          color: var(--whiteClr);
        }
      }
    }
  }
  .tab-content {
    .bank-details {
      margin-top: 22px;
      background: var(--secondaryClr);
      box-shadow: 5px 5px 10px #0000001a;
      border-radius: 10px;
      overflow: hidden;
      .top-area {
        background: var(--headClr);
        width: 100%;
        font-size: 14px;
        padding: 20px;
      }
      .bottom-area {
        padding: 20px;
        table {
          margin-bottom: 0;
          th {
            color: var(--whiteClr);
            font-size: 18px;
            font-weight: 500;
            padding: 5px 0;
          }
          td {
            color: var(--whiteClr);
            font-size: 14px;
            font-weight: 300;
            padding: 5px 0;
            width: 50%;
          }
        }
      }
    }
    .upi-list {
      margin-top: 40px;
      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        li {
          margin: 0 7px;
          width: 32px;
          border: none;
        }
      }
    }
    form {
      padding: 55px 30px 0;
      text-align: center;
      input {
        background: var(--headClr);
        border-radius: 10px;
        border-color: var(--headClr);
        padding: 14px 20px;
        &.form-check-input {
          border-radius: 50px;
          width: 24px;
          height: 24px;
          border: 2px solid var(--primaryClr);
          padding: 0;
          background-color: var(--whiteClr);
          position: relative;
          &:checked:after {
            content: "";
            background: var(--headClr);
            border-radius: 50px;
            width: 16px;
            height: 16px;
            display: block;
            position: absolute;
            top: 2px;
            left: 2px;
          }
        }
      }
      .btn {
        padding: 9px 30px;
        font-size: 14px;
        text-transform: uppercase;
        min-width: 120px;
      }
      .radio-btns {
        display: flex;
        .form-check {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 300;
          .form-check-label {
            margin-left: 5px;
            margin-top: 3px;
          }
        }
      }
    }
    .card-holder {
      height: 80px;
      background: var(--whiteClr);
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 5px 5px 10px #0000001a;
      img {
        max-width: 100px;
      }
    }
  }
  .h4 {
    font-size: 14px;
    font-weight: 500;
  }
  .sectionTitle {
    &::after {
      background: var(--headClr);
      width: 100%;
    }
  }
}
.custom-tabs {
  padding-top: 10px;
  .sports-name-listing {
    ul {
      flex-wrap: nowrap;
      padding: 10px;
      background: var(--secondaryClr);
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
      li {
        margin: 0 5px;
        a,
        div {
          color: #fff;
          padding: 5px;
          display: block;
          cursor: pointer;
          &:hover,
          &.active {
            background: transparent;
            color: var(--primaryClr);
          }
        }
      }
    }
  }
  .nav {
    flex-wrap: nowrap;
    background: var(--secondaryClr);
    margin-bottom: 15px;
    padding: 10px;
    display: flex;
    justify-content: center;
    .nav-item {
      margin: 0 5px;
      .nav-link {
        border-radius: 0;
        font-size: 12px;
        padding: 5px;
        color: var(--whiteClr);
        font-weight: 500;
        border: none;
        &:hover,
        &.active {
          background: transparent;
          color: var(--primaryClr);
        }
      }
    }
  }
  .tab-pane {
    .title {
      background: var(--headClr);
      margin: 0 0 10px;
      padding: 0 1rem;
      display: flex;
      justify-content: space-between;
      span {
        font-size: 14px;
        padding: 6px 1rem;
        display: inline-block;
        background: var(--primaryClr);
        color: var(--blackClr);
        margin-left: -1rem;
      }
    }
    .game-listing {
      .breadcrumb {
        font-size: 12px;
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
          font-size: 10px;
          color: #838383;
        }
      }
      .status {
        background: var(--primaryClr);
        color: var(--blackClr);
        display: flex;
        padding: 1px 5px;
        font-size: 9px;
        border-radius: 2px;
        align-items: center;
        justify-content: center;
      }
      .listing {
        display: flex;
        flex-wrap: nowrap;
        li {
          margin: 0 2px;
          width: 100%;
          .info-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: var(--blackClr);
            font-size: 10px;
            border-radius: 2px;
            padding: 2px 4px;
            cursor: pointer;
            span {
              font-size: 12px;
            }
            &.yes {
              background: var(--oddsBack);
            }
            &.no {
              background: var(--oddsLay);
            }
          }
        }
      }
    }
    .top-row {
      display: flex;
      justify-content: space-between;
      background: var(--headClr);
      margin: 20px -1rem;
      padding: 0 1rem;
      .bet-btns {
        display: flex;
        li {
          background: var(--primaryClr);
          color: var(--whiteClr);
          margin: 4px 0px 4px 4px;
          text-transform: uppercase;
          font-size: 11px;
          padding: 4px 8px;
          border-radius: 2px;
        }
      }
    }
    .status-btns {
      display: flex;
      align-items: center;
      .info-block {
        font-size: 10px;
        margin: 4px 0px 4px 4px;
        border-radius: 2px;
        padding: 3px 2px;
        min-width: 36px;
        text-align: center;
        color: var(--whiteClr);
        &.yes {
          background: var(--oddsBack);
        }
        &.no {
          background: var(--oddsLay);
        }
      }
    }
    .game-points {
      ul {
        margin: 0 -1rem;
        li {
          display: flex;
          border-bottom: 1px solid var(--secondaryClr);
          padding: 3px 1rem;
          align-items: center;
          font-size: 11px;
          .team-name {
            display: flex;
            flex-direction: column;
            line-height: normal;
            .time {
              color: var(--siteRedClr);
            }
          }
          .score {
            margin-left: auto;
          }
          .status-btns {
            margin-left: 5px;
            position: relative;
            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 8px;
              line-height: normal;
              span {
                font-size: 12px;
              }
            }
            .points-status {
              position: absolute;
              top: 4px;
              left: 4px;
              width: 95%;
              background: rgba(0, 0, 0, 0.7);
              font-size: 12px;
              color: var(--whiteClr);
              text-align: center;
              border-radius: 2px;
              height: 80%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
    .game-top-area {
      background: var(--headClr);
      margin: 0 -1rem;
      padding: 10px 1rem;
      .date {
        text-align: center;
        color: var(--primaryClr);
        font-size: 12px;
        margin-bottom: 5px;
      }
      .title {
        margin: 0;
        padding: 0;
        font-size: 14px;
      }
      .range {
        background: var(--primaryClr);
        font-size: 11px;
        color: var(--blackClr);
        padding: 2px 5px;
        line-height: normal;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.page-details {
  .top-row {
    display: flex;
    justify-content: space-between;
    background: var(--headClr);
    margin-bottom: 10px;
    padding: 0.5rem 1rem;
    ul.bet-btns {
      display: flex;
      li {
        background: var(--primaryClr);
        color: var(--blackClr);
        margin: 4px 0px 4px 4px;
        text-transform: uppercase;
        font-size: 11px;
        padding: 4px 8px;
        border-radius: 2px;
      }
    }
  }
  .game-top-area {
    background: var(--headClr);
    margin-bottom: 10px;
    padding: 10px 1rem;
    .date {
      text-align: center;
      color: var(--primaryClr);
      font-size: 12px;
      margin-bottom: 5px;
    }
    .title {
      margin: 0;
      padding: 0;
      font-size: 14px;
    }
    .range {
      background: var(--primaryClr);
      font-size: 11px;
      color: var(--blackClr);
      padding: 2px 5px;
      line-height: normal;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .title {
    background: var(--headClr);
    margin-bottom: 10px;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    span {
      font-size: 14px;
      padding: 6px 1rem;
      display: inline-block;
      background: var(--primaryClr);
      color: var(--blackClr);
      margin-left: -1rem;
    }
    .status-btns {
      display: flex;
      align-items: center;
      .info-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 8px;
        line-height: normal;
        font-size: 10px;
        margin: 4px 0px 4px 4px;
        border-radius: 2px;
        padding: 3px 2px;
        min-width: 36px;
        text-align: center;
        color: var(--blackClr);
        &.yes {
          background: var(--oddsBack);
        }
        &.no {
          background: var(--oddsLay);
        }
      }
    }
  }
  .game-points {
    ul {
      li {
        display: flex;
        border-bottom: 1px solid var(--secondaryClr);
        padding: 3px 0 3px 0.75rem;
        align-items: center;
        .score {
          margin-left: auto;
        }
        .team-name {
          display: flex;
          flex-direction: column;
          line-height: normal;
          .time {
            color: var(--siteRedClr);
          }
        }
        .status-btns {
          display: flex;
          align-items: center;
          position: relative;
          .points-status {
            position: absolute;
            top: 4px;
            left: 4px;
            width: 95%;
            background: rgba(0, 0, 0, 0.7);
            font-size: 12px;
            color: var(--whiteClr);
            text-align: center;
            border-radius: 2px;
            height: 80%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .info-block {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 8px;
          line-height: normal;
          font-size: 10px;
          margin-left: 4px;
          border-radius: 2px;
          padding: 5px 2px;
          width: 60px;
          text-align: center;
          color: var(--blackClr);
          cursor: pointer;
          &.yes {
            background: var(--oddsBack);
          }
          &.no {
            background: var(--oddsLay);
          }
          span {
            font-size: 12px;
          }
        }
      }
    }
  }
}
.footer {
  background: var(--headClr);
  padding: 30px 18px 80px 18px;
  margin-top: 20px;
  transition: all 0.3s;
  p {
    color: var(--whiteClr);
    opacity: 0.6;
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
  }
  .footer-logo {
    max-width: 150px;
    margin-bottom: 20px;
  }
  .paymentImages,
  .secureGaming {
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        padding: 8px;
        img {
          max-height: 35px;
          filter: grayscale(1);
          transition: all 0.2s;
          &:hover {
            filter: none;
          }
        }
      }
    }
  }
  .footer-links {
    background: var(--secondaryClr);
    display: inline-block;
    margin: 20px auto 10px auto;
    padding: 0 15px;
    width: 100%;
    ul {
      text-align: center;
      padding: 15px 0;
      li {
        font-size: 12px;
        font-weight: 300;
        display: inline-block;
        position: relative;
        margin: 5px 15px;
        &:after {
          content: " ";
          position: absolute;
          right: -16px;
          top: 2px;
          width: 1px;
          height: 14px;
          background: var(--headClr);
        }
        &:last-child {
          margin-right: 0;
          &::after {
            display: none;
          }
        }
        a {
          color: var(--whiteClr);
        }
      }
    }
  }
  .social-icons {
    padding-top: 25px;
    ul {
      display: flex;
      justify-content: center;
      li {
        margin-right: 15px;
        position: relative;
        &:last-child {
          margin-right: 0;
        }
        img {
          width: 40px;
          height: auto;
          cursor: pointer;
        }
        svg {
          width: 32px;
        }
        .notification-count {
          width: 12px;
          height: 12px;
          background: var(--siteRedClr);
          color: var(--whiteClr);
          border-radius: 50%;
          position: absolute;
          right: -2px;
          top: -2px;
          font-size: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .footer-menu {
    background-color: var(--headClr);
    box-shadow: 0 0px 14px rgb(255 189 0 / 12%);
    ul {
      li {
        text-transform: uppercase;
        font-size: 10px;
        a {
          padding: 15px 5px 10px 5px;
          color: var(--whiteClr);
          display: flex;
          flex-direction: column;
          align-items: center;
          text-decoration: none;
          cursor: pointer;
          svg,
          img {
            fill: currentColor;
            stroke: currentColor;
            max-width: 22px;
            max-height: 22px;
          }
          span {
            margin-top: 5px;
          }
          &.active {
            color: var(--primaryClr);
            svg {
              fill: currentColor;
              stroke: currentColor;
            }
          }
        }
      }
    }
  }
}
.agent-head {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  margin-top: 15px;
  .social-icon {
    width: 12%;
    margin: 0 2%;
    img {
      width: 100%;
    }
  }
}
.live_casino_page {
  // padding-top: 50px;

  // &.ezugi {
  //   background: rgb(35, 31, 32);
  // }
  &.live {
    background: var(--headClr);
    color: var(--whiteClr);
  }

  .header-bg {
    // background-color: #e3e2e2;
    .header {
      margin-bottom: 0;
    }

    .headerRight {
      fill: var(--primaryClr);
      .path-stroke {
        stroke: var(--primaryClr);
      }
    }
  }

  // @media screen and (min-width: 750px) {
  //   padding-top: 60px;
  // }

  // @media screen and (min-width: 1200px) {
  //   padding-top: 70px;
  // }

  scroll-snap-type: mandatory;

  .custom_placeholder_parent {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: rgba(87, 84, 85, 0.84);

    .custom_placeholder_child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 100px;
    }
  }
}
.form-control {
  background: transparent !important;
  color: var(--whiteClr) !important;
  &:focus {
    border-color: var(--primaryClr);
  }
}
.section-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
  h2 {
    margin-bottom: 0 !important;
  }
  a {
    background: var(--primaryClr);
    color: var(--blackClr);
    font-size: 15px;
    font-weight: 600;
    font-family: sans-serif;
    height: 40px;
    margin-left: 8px;
    padding: 10px 20px;
    border: 0;
    border-radius: 25px;
    transition: all 0.4s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
}

.betslip-content.inline_betslip {
  padding: 0.5rem;
  border: 1px solid var(--secondaryClr);
  box-shadow: 0 2px 4px rgba(255, 189, 0, 0.12);
  .stack-win-value {
    display: flex;
    margin-bottom: 5px;
    > div {
      width: calc(33.33% - 5px);
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }

    .odds,
    .stack_field_value {
      .form-control {
        border-color: var(--secondaryClr);
      }
    }
  }
  .winning p {
    line-height: 1.3;
    margin-bottom: 0;
  }
  .auto-stack {
    margin-bottom: 10px;
    ul {
      display: flex;
      align-items: center;
      li {
        width: 20%;
        margin-right: 0.5rem;
        &:last-child {
          margin-right: 0;
        }
        .stack-btn {
          background: var(--secondaryClr);
          color: var(--whiteClr);
          width: 100%;
        }
      }
    }
  }
  .btn_group {
    display: flex;
    > div {
      width: calc(50% - 5px);
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    .bet-now {
      .place-btn {
        background: var(--primaryClr);
        color: var(--blackClr);
        font-size: 1.15rem;
        font-weight: 600;
        width: 100%;
      }
    }
    .cancel-btn {
      background: var(--siteRedClr);
      color: var(--blackClr);
      font-size: 1.15rem;
      font-weight: 600;
      width: 100%;
    }
  }
}

.live_dealer_casino {
  padding: 20px 0;
  h4 {
    color: var(--whiteClr);
    margin-bottom: 10px;
  }
  .casino_games {
    ul {
      grid-gap: 11px;
      display: grid;
      gap: 11px;
      grid-auto-columns: calc(13.33% - 10px);
      grid-auto-flow: column;
      overflow-x: auto;
      padding-bottom: 10px;
      padding-top: 10px;
      scroll-behavior: smooth;
      scroll-snap-type: x mandatory;
      @media only screen and (max-width: 1299px) and (min-width: 1200px) {
        gap: 16px;
        grid-auto-columns: calc(15.38% - 15px);
      }
      &::-webkit-scrollbar {
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        background: #817a7a;
      }
      &::-webkit-scrollbar-thumb {
        background: #4c4c4c;
        border-radius: 10px;
      }
      li {
        a {
          img {
            width: 100%;
            height: auto;
            border-radius: 0.5rem;
          }
        }
      }
    }
  }
}

.casino_games_type {
  padding: 20px 0;
  .listings {
    margin-bottom: 30px;
    .text {
      margin: 0 0 10px;
    }
    .Evolution_Games {
      display: flex;
      justify-content: start;
      overflow-x: auto;
      padding-bottom: 5px;
      scrollbar-color: var(--headClr) var(--secondaryClr);
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        height: 8px;
      }
      &::-webkit-scrollbar-track {
        background: var(--secondaryClr);
      }
      &::-webkit-scrollbar-thumb {
        background: var(--headClr);
        &:hover {
          background: var(--headClr);
        }
      }
      .casino_games {
        width: calc(12.5% - 10.5px);
        min-width: calc(12.5% - 10.5px);
        margin-right: 12px;
        &:last-child {
          margin-right: 0;
        }
        .shadow.rounded.position-relative {
          box-shadow: 0px 0px 0px var(--blackClr) !important;
          text-align: center;
          img {
            width: 100%;
            height: auto;
            border-radius: 12px;
          }
        }
      }
    }
  }
}

.provider_sec {
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    li {
      max-width: 150px;
      height: auto;
      margin: 0 5px 10px;
      img {
        border-radius: 7px;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .header_open {
    .header,
    .main,
    .footer,
    .iframe_sec,
    .ezugi_iframe {
      width: calc(100% - 230px);
      margin-left: 230px;
      transition: all 0.3s;
    }
    header {
      .logo {
        display: none;
      }
    }
  }
  .mainBanner {
    display: none !important;
  }
}

@media only screen and (max-width: 1199.98px) {
  .casino_games_type .listings .Evolution_Games .casino_games {
    width: calc(16.67% - 10px);
    min-width: calc(16.67% - 10px);
  }
  .main .TopCategories .tab-content {
    max-height: 330px;
  }
}

@media only screen and (max-width: 991.98px) {
  .mainBanner {
    .banner_info {
      flex-wrap: wrap;
      margin-top: 0;
      margin-bottom: 0.5rem;
      padding: 1rem;
      h2 {
        order: 0;
        font-size: 1rem;
      }
      .payment {
        order: 2;
        width: 100%;
        justify-content: center;
        margin-top: 1rem;
      }
      .btn-primary {
        order: 1;
      }
    }
  }

  .casino_games_type {
    .listings {
      .Evolution_Games {
        .casino_games {
          width: calc(25% - 9px);
          min-width: calc(25% - 9px);
        }
      }
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .whatsapp-ready span {
    display: none;
  }
  .loading_img {
    position: absolute;
    width: 25%;
    height: auto;
    left: 38%;
    top: 188px;
  }
  .loading_img2 {
    position: absolute;
    width: 22%;
    height: auto;
    left: 40%;
    top: 200px;
  }
  .main {
    .TopCategories {
      .tab-content {
        max-height: 250px;
      }
    }
    .games_tab {
      ul {
        &::-webkit-scrollbar-thumb {
          background: rgb(76, 81, 89);
        }
      }
    }
  }

  .agent-head {
    justify-content: space-between;
    .social-icon {
      width: 40%;
      margin: 2%;
    }
  }
  .header {
    padding: 13px 0 !important;
    &.aftrlgn {
      .logo {
        margin-left: 35px;
      }
    }
    .navbar {
      .navbar-toggler {
        top: 18px;
        right: -37px;
        padding: 0 0.15rem;
        line-height: 16px;
        border-radius: 0.375rem;
        .navbar-toggler-icon {
          height: 20px;
          width: 18px;
        }
      }
    }
    .headerRight {
      .menu-btn {
        margin-left: 0;
      }
      .funds ul {
        margin-right: 0px;
        padding: 0px !important;
        background: inherit !important;
        li {
          margin: 0 4px !important;
          a {
            color: var(--whiteClr);
            background: var(--secondaryClr);
            display: block;
            border-radius: 22px;
            padding: 3px 10px !important;
            span {
              padding-left: 2px;
            }
          }
        }
        li:after {
          display: none;
        }
      }
      ul {
        li {
          margin: 0 2px;
        }
      }
    }
  }
  .tabs-area .tab-content .card-holder img {
    max-width: 80px;
  }
  .custom-tabs {
    padding-top: 0px;
  }
  .custom-tabs .sports-name-listing ul {
    padding: 5px;
    margin-bottom: 5px;
  }
  .page-details .game-points ul li {
    display: block;
    padding-left: 0;
  }
  .page-details .game-points ul li .team-name {
    width: 100%;
    padding-left: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .page-details .game-points ul li .status-btns {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .page-details .game-points ul li .info-block {
    width: inherit;
    &:first-child {
      margin-left: 0;
    }
  }
  .casino_games_type {
    .listings {
      .Evolution_Games {
        .casino_games {
          width: calc(33.33% - 8px);
          min-width: calc(33.33% - 8px);
        }
      }
    }
  }
  .ezugi_iframe {
    iframe {
      padding-top: 0 !important;
    }
  }
}

@media only screen and (max-width: 575px) {
  .main {
    .TopCategories {
      .tab-content {
        max-height: 365px;
      }
    }
  }
  .live_dealer_casino {
    .casino_games {
      ul {
        gap: 6px;
        grid-auto-columns: calc(33.33% - 5px);
      }
    }
  }
}

@media only screen and (max-width: 521px) {
  .main {
    .TopCategories {
      .tab-content {
        max-height: 330px;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .casino_games_type {
    .listings {
      .Evolution_Games {
        .casino_games {
          width: calc(50% - 6px);
          min-width: calc(50% - 6px);
        }
      }
    }
  }
  .main {
    .TopCategories {
      .tab-content {
        max-height: 290px;
      }
    }
  }
}

@media only screen and (max-width: 421px) {
  .main {
    .TopCategories {
      .tab-content {
        max-height: 265px;
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .header {
    .headerRight {
      .funds {
        ul {
          li {
            a {
              display: flex;
              border-radius: 22px;
              font-size: 12px;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .main {
    .TopCategories {
      .tab-content {
        max-height: 250px;
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  .main {
    .TopCategories {
      .tab-content {
        max-height: 240px;
      }
    }
  }
}

.changes_password {
  width: 100%;
  padding: 10px 10px;
  margin: 5px 0px;
  border: 1px solid goldenrod;
  border-radius: 5px;
  color: black !important;
  background: transparent;
}

.contact-section {
  h3 {
    color: #fff;
    margin-bottom: 26px;
    margin-top: 15px;
  }
  .contact-content {
    display: flex;
    align-items: center;
    img {
      width: 54px;
      // height: 60px;
      margin-right: 20px;
    }
  }
}
.privacy_policy {
  padding-bottom: 71px;
  h3 {
    color: #fff;
    margin-bottom: 26px;
    margin-top: 15px;
  }
}
.exch-funds-form {
  // max-height: 100px;
  > .trigger {
    position: fixed;
    bottom: 100px;
    right: 10px;
    padding: 5px 10px;
    background-color: #296ca3;
    border-radius: 10px;
    // color:

    @media screen and (min-width: 768px) {
      bottom: 40px;
      right: 20px;
    }

    font-size: 16px;
    color: #fff;
    display: flex;
    align-items: center;

    svg {
      margin-right: 14px;
      fill: #fff;
      stroke: #fff;
    }
  }
}

.ExchangeFundTopUp {
  z-index: 99999;
  .modal-header {
    background: var(--headClr);
    color: #fff;
    border: 0;
  }
  .modal-body {
    background-color: var(--bodyClr);
  }
}
